import React, {useState, useCallback, createRef} from 'react';
import {ReactComponent as Reactlogo}  from './react-brands.svg';
import {ReactComponent as Htmllogo} from './html5-brands.svg';
import {ReactComponent as Csslogo} from './css3-alt-brands.svg';
import {ReactComponent as Jslogo} from './js-brands.svg';
import {ReactComponent as AndroidLogo} from './android-brands.svg';
import {ReactComponent as JavaLogo} from './java-brands.svg';
import john from './avatar.webp'
import jeopardy1 from './Jeoparody/jeoparody_start.webp'
import jeoparody2 from './Jeoparody/jeoparody_2.webp'
import jeoparody3 from './Jeoparody/jeoparody_home.webp'
import jeopardy4 from './Jeoparody/jeoparody_question.webp'
import campus_map1 from './CampusMap/campusmap1.webp'
import campus_map2 from './CampusMap/campusmap2.webp'
import campus_map3 from './CampusMap/campusmap3.webp'
import campus_map4 from './CampusMap/campusmap4.webp'
import campus_map5 from './CampusMap/campusmap5.webp'
import realEstate1 from './RealEstate/RE1.webp'
import realEstate2 from './RealEstate/RE2.webp'
import realEstate3 from './RealEstate/RE3.webp'
import portfolio1 from './Portfolio/Portfolio1w.webp'
import portfolio2 from './Portfolio/Portfolio2.webp'

import {Linkedin, Github, CheckCircle, Globe2, BootstrapFill, Google, InfoCircle, ExclamationOctagon } from 'react-bootstrap-icons'
import './Home.css';
import { Card, Container, Col, Button, Form,  Navbar, Nav, NavLink, NavbarBrand, Carousel, Row, Image, OverlayTrigger, Popover, Tooltip, Toast, ToastContainer} from 'react-bootstrap';
import { animated, useSpring} from 'react-spring';
import firebase from "firebase/app";
// import "firebase/storage"
import "firebase/database"
import "firebase/app-check"

  // Set the configuration for your app
  // TODO: Replace with your project's config object
  const firebaseConfig = {
    apiKey: "AIzaSyDSns6Lww4xCMtDarHjYUYZxQQRHPlfTPo",
    authDomain: "portfolio-6e555.firebaseapp.com",
    projectId: "portfolio-6e555",
    storageBucket: "portfolio-6e555.appspot.com",
    messagingSenderId: "299938899599",
    appId: "1:299938899599:web:fec39ee8dd1d728ab972fa",
    measurementId: "G-JPG886DWQM"
  };
  firebase.initializeApp(firebaseConfig);

  //reCAPTCHA v3
  const appCheck = firebase.appCheck();
  
  appCheck.activate('6LdpA_8bAAAAACTFW87CEHa5SM4Vc8BRX6hk060F', true);
  // Get a reference to the database service
  var database = firebase.database();
  // Get a reference to the storage service
  // var storage =firebase.storage();

const ref = createRef();
const projectRef = createRef();
const aboutRef = createRef();
const contactRef = createRef();
const mql=window.matchMedia('(max-width: 992px)');


function scrollToHome(){
  ref.current.scrollIntoView({behavior: 'smooth'})
}
function scrollToProjects(){
  projectRef.current.scrollIntoView({behavior: 'smooth'})
}
function scrollToAbout(){
  aboutRef.current.scrollIntoView({behavior: 'smooth'})
}
function scrollToContact(){
  contactRef.current.scrollIntoView({behavior: 'smooth'})
}

function ScrollIndicator(props){
  if(props.circles)
    return(
        <div id="Projects-scroll-indicator" >
              {props.circles.map(item => (props.active === item ? <div style={{height:"8px", width:"8px"}} /> : <div/> )            
              )}
          </div>
    )
  else return null;
}


function FadeInSkills(props){

  const [fade, fadeIn] = useState(false);
  
  const didFade = useCallback(
    () => {
    if(props.skills === "Scroller-active"){fadeIn(true);}
    else fadeIn(false);
  },[props.skills]);
  
  const spring = useSpring({
    from: { filter: "blur(20px)", opacity: .1 },
    to:{filter: "blur(0px)", opacity: 1},
    config: {duration: 1500},
    reverse: fade,
    onProps: didFade,
  })
  return ( <animated.ul id="skills" style={spring}>
      <li>Node.js</li>
      <li>Java</li>
      <li>Javascript</li>
      <li>Bootstrap</li>
      <li>C#</li>
      <li>React.js</li>
      <li>Android</li>
      <li>HTML</li>
      <li>CSS</li>
    </animated.ul> 
   )

}

function Navi(props){
  return(<header className="Navi" >
  <Navbar className="px-1" id={props.scrollId} collapseOnSelect variant="dark" expand="sm" fixed="top" > 
  <NavbarBrand className="mx-3" onClick={props.brandClicked}>John Hornijas</NavbarBrand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse className="flex-row-reverse" >
      <Nav className="d-flex mx-3" activeKey={props.active}  >
        <NavLink  onClick={scrollToHome} eventKey={"home"} >Home</NavLink>
        <NavLink onClick={scrollToProjects} eventKey={"projects"} >Projects</NavLink>
        <NavLink onClick={scrollToAbout} eventKey={"about"}>About</NavLink>
        <NavLink onClick={scrollToContact} eventKey={"contact"}>Contact</NavLink>
      </Nav>
    </Navbar.Collapse>
    
  </Navbar>
  
</header>);
}
function Home(props) {
  return ( 
  <section  >
    <div className="Background"></div>
    <div ref={ref} className="Home" name="home" onScroll={props.onFocus} > 
    <article>
      {/* <h1 className="mb-5" >&lt; John Hornijas &#47;&gt;</h1> */}
      <img id="avatar" src={john} alt="John"/>
      <FadeInSkills skills={props.skills}/>
    </article>
    </div>
  </section>
  );
}

function Projects(props){

  const jeoparody = [jeopardy1, jeoparody2, jeoparody3, jeopardy4];
  const campus_map= [campus_map1, campus_map2, campus_map3, campus_map4, campus_map5];
  const real_estate = [realEstate1, realEstate2, realEstate3];
  const portfolio = [portfolio1,portfolio2]


  // var clicked = item => setJeoparody(state => [(state[item] + 1) % 4, ...state])
  // from:{opacity: 0, transform: 'translate3d(50%,0,0'},

  // const transitions =useTransition(index,{ 
  //   from:{opacity: 0, transform: 'translate3d(50%,0,0'},
  //   enter:{position: 'absolute', opacity: 1, transform: 'translate3d(0%,0,0'},
  //   leave:{opacity: 0, transform: 'translate3d(-20%,0%,0'},
  //   })



  return(
      <Container id="Projects-container" name ="projects"  ref={projectRef}  fluid className="Projects">
          <h1 className="mb-4">&lt;Projects&#47;&gt;</h1>
          <div   id='Projects' onTouchMove={props.onTouch} onScroll={props.onScroll}>
          <Card  id="Jeoparody" text='dark' border='secondary' >   
            <Carousel className='w-100' variant="dark"  interval={null} touch={false}  >
              {jeoparody.map(v=><Carousel.Item  > <img className=" w-100" loading="lazy" src={v} alt="JeoparodyPic" /></Carousel.Item>)}
              </Carousel>
              <Card.Body>
                <Card.Title className="pb-1">Jeoparody</Card.Title>
                <Card.Text as="div" className="d-flex ">
                  <ul  className="w-50 px-0" id="tech-logo" title="technologies">
                    <li><Reactlogo title="reactjs" width="18" height="18"></Reactlogo></li>
                    <li><Htmllogo  title="html5" width="18" height="18" ></Htmllogo></li>
                    <li><Csslogo alt="css3_logo" title="css3" width="18" height="18" ></Csslogo></li>
                    <li><Jslogo alt="js_logo" title="javascript" width="18" height="18"  ></Jslogo></li>
                  </ul>
                  <ul className="w-50 px-0" >
                    <li><a className="pe-auto" href="https://github.com/shreddcoder88/jeoparody" target="__blank" variant="primary" title="Github link"><Github size="18"/></a></li>
                    <li><a className="pe-auto" href="https://jeoparody.projekjv.dev/" target="__blank" variant="primary" title="Launch"><Globe2 size="18"/></a></li>
                    <li><OverlayTrigger variant="primary" trigger={["hover","click","focus"]} placement="left" 
                          overlay={<Popover>
                                      <Popover.Body>
                                        A web app that is based on the popular tv show "Jeopardy"
                                      </Popover.Body>
                                    </Popover>}>
                          <InfoCircle size="18" className="pe-auto" color="royalblue" />
                        </OverlayTrigger>
                    </li>
                  </ul>
                </Card.Text>
            </Card.Body>
          </Card>
          <Card   id="RealEstate"  border='secondary'>
          {/* {transitions((style, i) => <animated.img variant="top" src={jeoparody[i]} style={style} alt="Jeoparody Pictures" onClick ={()=>setRePic(rePic+1)}/>)} */}
            <Carousel className='w-100' variant='dark' interval={null} touch={false}>
              {real_estate.map(v=><Carousel.Item  > <Image loading="lazy" className="w-100" src={v} alt="RealEstatePics" fluid/></Carousel.Item>)}
            </Carousel>
            <Card.Body>          
              <Card.Title className="pb-1" >Real Estate</Card.Title>
              <Card.Text as="div" className="d-flex justify-content-between">
                <ul className="w-50 px-0" id="tech-logo" title="technologies">
                  <li><Reactlogo title="reactjs" width="18" height="18"></Reactlogo></li>
                  <li><Htmllogo  title="html5" width="18" height="18" ></Htmllogo></li>
                  <li><Csslogo alt="css3_logo" title="css3" width="18" height="18" ></Csslogo></li>
                  <li><Jslogo alt="js_logo" title="javascript" width="18" height="18"  ></Jslogo></li>
                  <li title="bootstrap"><BootstrapFill alt="bs_logo" title="Bootstrap" size="18"/></li>
                </ul>
                <ul className="w-50 px-0" >
                  <li><a className="pe-auto" href="#github"  title="Github link"><Github color="gray" size="18"/></a></li>
                  <li><a className="pe-auto" href="#App"  title="Launch"><Globe2 color="gray" size="18"/></a></li>
                  <li><OverlayTrigger variant="primary" trigger={["hover","click","focus"]} placement="left" 
                          overlay={<Popover>
                                      <Popover.Body>
                                        A website that I designed for a client using inVision Studio. <br/> Next.js will be used as the framework for development
                                      </Popover.Body>
                                    </Popover>}>
                          <InfoCircle size="18" className="pe-auto" color="royalblue" />
                        </OverlayTrigger></li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card id="CampusMap" text='dark' border='secondary'>
            <Carousel  className='w-100' variant="dark"  interval={null} touch={false}>
              {campus_map.map(v=><Carousel.Item  > <img className=" w-auto" loading="lazy" src={v} alt="CampusMapPic" /></Carousel.Item>)}
            </Carousel>       
            <Card.Body>          
            <Card.Title className="pb-1" >Campus Map</Card.Title>
            <Card.Text as="div" className="d-flex justify-content-between">
              <ul  className="w-50 px-0" id="tech-logo" title="technologies">
                <li><AndroidLogo title="android" width="18" height="18"></AndroidLogo></li>
                <li><JavaLogo  title="java" width="18" height="18" ></JavaLogo></li>
                <li><Htmllogo alt="css3_logo" title="html" width="18" height="18" ></Htmllogo></li>
                <li><Jslogo alt="js_logo" title="javascript" width="18" height="18"  ></Jslogo></li>
              </ul>
              <ul className="w-50 px-0" >
                <li><a className="pe-auto" href="#github" title="Github link"><Github  size="18"/></a></li>
                <li><a className="pe-auto" href="#App" title="Launch"><Globe2 size="18"/></a></li>
                <li><OverlayTrigger variant="primary" trigger={["hover","click","focus"]} placement="left" 
                          overlay={<Popover>
                                      <Popover.Body>
                                        An android app that helps students/staff navigate campus grounds
                                      </Popover.Body>
                                    </Popover>}>
                          <InfoCircle size="18" className="pe-auto" color="royalblue"  />
                        </OverlayTrigger></li>
              </ul>
            </Card.Text>
          </Card.Body>
          </Card>
          {/* <Card id="Barcode Copier" text='dark' border='secondary'>
          <Card.Body>          
            <Card.Title className="pb-1" >Barcode Copier</Card.Title>
            <Card.Text className="d-flex justify-content-between">
              <ul  className="w-50 px-0" id="tech-logo" title="technologies">
                <li><Reactlogo title="reactjs" width="18" height="18"></Reactlogo></li>
                <li><Htmllogo  title="html5" width="18" height="18" ></Htmllogo></li>
                <li><Csslogo alt="css3_logo" title="css3" width="18" height="18" ></Csslogo></li>
                <li><Jslogo alt="js_logo" title="javascript" width="18" height="18"  ></Jslogo></li>
              </ul>
              <ul className="w-50 px-0" >
                <li><a className="pe-auto" href="#github" variant="primary" title="Github link"><Github size="18"/></a></li>
                <li><a className="pe-auto" href="#App" variant="primary" title="Launch"><Globe2 size="18"/></a></li>
                <li><a className="pe-auto" href="#Detail" variant="primary" title="Expand"><BoxArrowUpRight size="18"/></a></li>
              </ul>
            </Card.Text>
          </Card.Body>
          </Card> */}

          <Card id="Portfolio" >
            <Carousel className='w-100' variant="dark"  interval={null}  touch={false}>
            {portfolio.map(v=><Carousel.Item  > <img className=" w-100" loading="lazy" src={v} alt="Portfolio Pics" /></Carousel.Item>)}
            
            </Carousel>
            <Card.Body>
              <Card.Title className="pb-1">Portfolio</Card.Title>
              <Card.Text as="div" className="d-flex ">
                <ul  className="w-50 px-0" id="tech-logo" title="technologies">
                  <li><Reactlogo title="reactjs" width="18" height="18"></Reactlogo></li>
                  <li><Htmllogo  title="html5" width="18" height="18" ></Htmllogo></li>
                  <li><Csslogo alt="css3_logo" title="css3" width="18" height="18" ></Csslogo></li>
                  <li><Jslogo alt="js_logo" title="javascript" width="18" height="18"  ></Jslogo></li>
                </ul>
                <ul className="w-50 px-0" >
                  <li><a className="pe-auto" href="#github"  title="Github link"><Github color="gray" size="18"/></a></li>
                  <li><a className="pe-auto" href="#App"  title="Launch"><Globe2 color="gray" size="18"/></a></li>
                  <li><OverlayTrigger variant="primary" trigger={["hover","click","focus"]} placement="left" 
                          overlay={<Popover>
                                      <Popover.Body>
                                        A personal website that shows my projects <br/> Built with Create React and React Bootstrap
                                      </Popover.Body>
                                    </Popover>}>
                          <InfoCircle size="18" className="pe-auto" color="royalblue"  />
                        </OverlayTrigger></li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>        
            
          {props.scrollable ? <ScrollIndicator active={props.active} circles={props.circles}/> : null }
      </Container>
    );
}

function About(){
  return(<Container name="about" id="about" ref={aboutRef} fluid className="About">
    <h1 className="mb-5">&lt;About&#47;&gt;</h1>
  <section id='About-card' >    
    <article className="my-5"><h2>Hello World!</h2><p>  Hi! My name is John. I am a developer focused on design, mobile and web development. 
      A big techie that I am, I eventually want to learn new emerging technologies such as machine learning and blockchain.</p> 
      <p>
      My hobbies include video games, riding my motorcycle, playing basketball and driving along the coast. 
      If you have any interesting proposition/s or ambitious projects. Contact me down below! I love a good&nbsp;
      <OverlayTrigger variant="primary" trigger={["hover","click","focus"]} placement="top" 
        overlay={<Tooltip>
                  "We choose to go to the Moon in this decade and do the other things, not because they are easy, but because they are hard"
                  <br/><br/> -John F. Kennedy
                  </Tooltip>}>
        <a href="#JFK">challenge</a>
      </OverlayTrigger> 
      </p>
      </article>
    </section>

  </Container>)
}

function Contact(props){
  
  return(<section name="contact" ref={contactRef} className="Contact-container">
            <h1>&lt;Contact&#47;&gt;</h1>
            
            <Form noValidate validated={props.validated} onSubmit={props.submit} >
              <Row>
                <Form.Group as={Col} controlId="contactFirstName" >
                  <Form.Label>First name</Form.Label><Form.Control type='name' placeholder='e.g. Tommy' required/>
                  <Form.Control.Feedback type="invalid">Please provide a name</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="contactLastName" >
                  <Form.Label>Last name</Form.Label><Form.Control type='name' placeholder='e.g. Tutone'required/> 
                  <Form.Control.Feedback type="invalid">Please provide a name</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="contactPhone">
                  <Form.Label>Phone number</Form.Label>
                  <Form.Control type='tel' placeholder='e.g. 867-5309' required/>
                  <Form.Control.Feedback type="invalid">Please provide a phone number</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="contactEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type='email' placeholder='e.g. jenny@gmail.com' required/>
                  <Form.Control.Feedback type="invalid">Please provide an email</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Form.Group controlId="contactMessage">
                <Form.Label>Your message</Form.Label>
                <Form.Control  as='textarea' type="message" placeholder='Message' rows={5} required/>
              </Form.Group>
              <Form.Group>
                <Button className="d-flex mx-auto mt-3"  type="submit" variant="primary">Submit{props.submitted? (props.submitted?<> &nbsp;<CheckCircle size="24" color="lightgreen"/></>: null) : (props.notSubmitted? <>&nbsp;<ExclamationOctagon size="24" color="red"/></> : null)}</Button>
              </Form.Group>
            </Form>
            <div aria-live="polite" aria-atomic="true" className="bg-secondary position-relative" ><ToastContainer style={{maxWidth : '275px', paddingBottom: '10%'}} className="position-fixed"position="bottom-end">
                <Toast onClose={props.close} show={props.submitted||props.notSubmitted? true : false} delay={5000} autohide><Toast.Header>
                  <Toast.Body className="text-dark m-auto" >{props.notify} </Toast.Body></Toast.Header>
                </Toast>
              </ToastContainer></div>
            <footer  className="d-flex mb-2">
                <small>© Copyright John Hornijas, All rights reserved.</small>
                <Nav className="pl-5 pr-5">
                  <a rel="noreferrer" href="https://www.linkedin.com/in/johnhornijas/" target="_blank"><Linkedin className="mx-2" color="aliceblue" size="32"/></a>
                  <a rel="noreferrer" href="https://github.com/shreddcoder88"  target="_blank"><Github className="mx-2" size="32" color="aliceblue"></Github></a>
                  <a rel="noreferrer" href="https://developers.google.com/profile/u/106392395430919081289" target="_blank"><Google className="mx-2" color="aliceblue" size="32"/></a>
                </Nav>
            </footer>
          </section>)
}


class Portfolio extends React.Component{
  constructor(props){
  super(props);
  this.state = {
    active: "home",
    carousel: 1 ,
    scrollable: false ,
    validated: false
  }
  this.handleProjectScroll = this.handleProjectScroll.bind(this);  
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleWindowResize = this.handleWindowResize.bind(this);
  this.handleClose  = this.handleClose.bind(this);
  };
      
  componentDidMount(){
    
    window.onresize = () => this.handleWindowResize(mql);
    if(mql.matches){this.handleWindowResize()}
    document.addEventListener('scroll',()=>this.handleScroll());
  }

  componentDidUpdate(_prevProps, prevState){
    if(prevState.submitted){setTimeout(()=>this.setState( {submitted: false}),5000 )};
  }

  componentWillUnmount(){
    document.removeEventListener("scroll",()=>{
      this.handleScroll();
    })
    mql.removeEventListener("change",()=>{this.handleWindowResize()});

  }
  
  handleSubmit(e){
    e.preventDefault();
    const form = e.target;
    const time = Date.now();

    if(form.checkValidity() === false){
      this.setState({validated: true});
      return;
    }
    
    
    database.ref('contact/').push().set({
      firstName: form["contactFirstName"].value,
      lastName: form["contactLastName"].value,
      phone: form["contactPhone"].value,
      email: form["contactEmail"].value,
      message: form["contactMessage"].value,
      time:time
    }).then(()=>this.setState({submitted: true, notification: "Your message was submitted.", notSubmitted : false})).catch(this.setState({notSubmitted: true ,notification: "Error: Invalid Authentication"}))
    

    
  }

  handleWindowResize(){
    if(mql.matches){
      const carouselActive = document.getElementById("Projects");
      const numOfCol = Math.trunc(carouselActive.scrollWidth/ carouselActive.clientWidth);

    const circles = [];
    circles.length = numOfCol;

    for (let index = 1; index <= numOfCol; index++) {
      circles.fill(index, index-1);
    }       
    
    this.setState({circles: circles, width: carouselActive.scrollWidth, scrollable: true})
    }   
    else this.setState({scrollable: false});
    
  }

  handleClose(){
    this.setState({submitted : false , notSubmitted : false});
  }

  handleScroll(){
    const height = (document.body.scrollHeight - window.innerHeight) 

    let home = height/4;
    let projects = height/4*2;
    let about =height/4*3;
    if(window.pageYOffset > 0){
      this.setState({scrollerId: 'Scroller-active'})
    }
    else {this.setState({scrollerId: 'Scroller'})}
    
    if (window.pageYOffset<=home) {
      this.setState({active: "home"})
      
    }
    else if(window.pageYOffset>(home) && (window.pageYOffset < projects)){
      this.setState({active: "projects"});

    }
    else if(window.pageYOffset<about && window.pageYOffset>projects ){
      this.setState({active: "about"});
    }
    else {
      this.setState({active: "contact"});}
  }
  handleProjectScroll(e){
    const carouselActive = e.target;
    const position = carouselActive.scrollLeft;
    const scrollable = carouselActive.scrollWidth - carouselActive.clientWidth;
    const scrollWidth = carouselActive.scrollWidth;
    const clientWidth = carouselActive.clientWidth;
    //const offsetWidth = carouselActive.offsetWidth;

    // console.info("Client width", clientWidth);
    // console.info("offset width", offsetWidth);
    // console.info("Scrolled", scrollable);
    // console.info("Scroll Left", position);
    // console.info("Scroll width", scrollWidth);

    const numOfCol = Math.trunc(scrollWidth / clientWidth) ;
    
    let compare = 0;

    for (let index = 1; index <= numOfCol; index++) {    
        if(position < (scrollable/numOfCol * index) && position > compare) this.setState({carousel: index});
        compare = scrollable/numOfCol *index   
    }
           

  }
   
  render(){

    return <><Navi scrollId={this.state.scrollerId} brandClicked={this.handleFocus} active={this.state.active}></Navi>
            <Home onFocus={this.handleFocus} skills={this.state.scrollerId}  />
            <Projects active={this.state.carousel} scrollable={this.state.scrollable} circles={this.state.circles} onTouch={this.handleProjectScroll} onScroll={this.handleProjectScroll}/>
            <About onFocus={this.handleFocus}/>
            <Contact submitted={this.state.submitted} notSubmitted={this.state.notSubmitted} notify={this.state.notification} close={this.handleClose} validated={this.state.validated} submit={this.handleSubmit} onFocus={this.handleFocus}/>
            </>
            
  }

}

export default Portfolio;
